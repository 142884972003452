import styled from 'styled-components'
import pattern from 'src/img/background_pattern.svg'

export const Inner = styled.div`
  position: relative;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const PatternBG = styled.div`
  position: absolute;
  ${props => props.positionX}: -24px;
  ${props => props.positionY}: -24px;
  ${props => (props.hasMaxHeight ? 'max-height: 140px' : '')};
  height: 90%;
  width: 140px;
  background-image: url(${pattern});
`
