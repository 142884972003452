import React from 'react'
import PropTypes from 'prop-types'
import jobIcon from 'src/img/jobIcon.svg'

import { Link, Box, Background, Title, Type } from './style'

const JobBox = ({ title, type, url, hasIcon }) => (
  <Link href={url} target="_blank" rel="noopener noreferrer">
    <Box
      px={{ xs: 2, lg: 4 }}
      py={{ xs: 6, lg: 8 }}
      mt={{ xs: hasIcon ? 4 : 0, lg: 0 }}
    >
      {hasIcon && (
        <Background>
          <img src={jobIcon} alt="" />
        </Background>
      )}
      <Title>{title}</Title>
      <Type>{type}</Type>
    </Box>
  </Link>
)

JobBox.defaultProps = {
  hasIcon: false,
}

JobBox.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
}

export default JobBox
