import React from 'react'
import PropTypes from 'prop-types'
import { Inner, PatternBG, Wrapper } from './style'

const Pattern = ({ positionX, positionY, hasMaxHeight, children }) => {
  return (
    <Wrapper>
      <PatternBG
        positionX={positionX}
        positionY={positionY}
        hasMaxHeight={hasMaxHeight}
      />
      <Inner>{children}</Inner>
    </Wrapper>
  )
}

Pattern.propTypes = {
  children: PropTypes.node.isRequired,
  positionX: PropTypes.string,
  positionY: PropTypes.string,
  hasMaxHeight: PropTypes.bool,
}

Pattern.defaultProps = {
  positionX: 'left',
  positionY: 'top',
  hasMaxHeight: false,
}

export default Pattern
