import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Section from 'src/components/Section'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'

const HeadingWysiwyg = ({
  background,
  preHeading,
  heading,
  textWysiwyg,
  children,
  beforeText,
  afterText,
  beforeFooter,
}) => (
  <Section
    background={background}
    hasPaddingLarge
    paddingValuesLarge={
      beforeFooter ? { xs: '48px 0  96px 0', lg: '100px 0 148px 0' } : undefined
    }
  >
    <Grid container>
      <Grid item xs={12} md={10}>
        <Heading2Line preHeading={preHeading} heading={heading} />
        {beforeText}
        <Wysiwyg>{textWysiwyg}</Wysiwyg>
        {afterText}
      </Grid>
    </Grid>
    {children}
  </Section>
)

HeadingWysiwyg.propTypes = {
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  beforeFooter: PropTypes.bool,
  beforeText: PropTypes.node,
  afterText: PropTypes.node,
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  textWysiwyg: PropTypes.string.isRequired,
  children: PropTypes.node,
}

HeadingWysiwyg.defaultProps = {
  beforeFooter: undefined,
  beforeText: undefined,
  afterText: undefined,
  background: undefined,
  children: null,
}

export default HeadingWysiwyg
