import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import { getThemeVar } from 'src/styles/mixins'
import { Grid, Box } from '@material-ui/core'
import HeadingWysiwyg from 'src/sections/HeadingWysiwyg'
import SectionPattern from 'src/components/SectionPattern'
import Heading2Line from 'src/components/Heading2Line'
import Pattern from 'src/components/Pattern'
import JobBox from 'src/components/JobBox'
import styled from 'styled-components'

const NoOpenPosition = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  padding: 32px;
  border-radius: 4px;
  background-color: ${props => props.theme.color.lightblue}
`

export const CareerTemplate = ({ acf }) => {
  const intro = acf.careerIntro
  const jobs = acf.careerJobs

  return (
    <>
      <HeadingWysiwyg
        background={getThemeVar('color.lightblue')}
        preHeading={intro.preHeading}
        heading={intro.heading}
        textWysiwyg={intro.textWysiwyg}
      >
        <SectionPattern positionX="right" />
      </HeadingWysiwyg>

      <Section
        hasPaddingLarge
        background={getThemeVar('color.white')}
        paddingValuesLarge={{ xs: '48px 0  96px 0', lg: '100px 0 148px 0' }}
      >
        <Heading2Line preHeading={jobs.preHeading} heading={jobs.heading} />
        {jobs && jobs.jobs && (
          <>
            <Pattern positionX="left" positionY="bottom" hasMaxHeight>
              <Box mt={{ xs: 0, lg: 10 }}>
                <Grid container spacing={4}>
                  {jobs.jobs.map((job, i) => {
                    return (
                      <Grid item xs={12} lg={4} key={job.jobTitle}>
                        <JobBox
                          title={job.jobTitle}
                          url={job.jobDescription.url}
                          type={job.fulltimeParttime}
                          hasIcon={i === 0}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Pattern>
          </>
        )}

        { !jobs.jobs && (
          <Box mt={4}>
            <Grid container>
              <Grid item xs={12}>
                <NoOpenPosition>
                  {jobs.noOpenPositions}
                </NoOpenPosition>
              </Grid>
            </Grid>
          </Box>
        )}
      </Section>
    </>
  )
}

CareerTemplate.defaultProps = {
  acf: {
    careerIntro: {
      preHeading: '',
      heading: '',
      textWysiwyg: '',
    },
    careerJobs: {
      preHeading: '',
      heading: '',
    },
  },
}

CareerTemplate.propTypes = {
  acf: PropTypes.shape({
    careerIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),
    careerJobs: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      jobs: PropTypes.arrayOf(
        PropTypes.shape({
          jobTitle: PropTypes.string,
          jobDescription: PropTypes.shape({
            url: PropTypes.string,
          }).isRequired,
          fulltimeParttime: PropTypes.string,
        })
      ).isRequired,
      noOpenPositions: PropTypes.isRequired,
    }),
  }),
}

export default CareerTemplate
