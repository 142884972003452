import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'
import { breakpoint } from 'src/styles/mixins'
import { darken } from 'polished'

import {
  LinkWithArrowStyle,
  TextDefaultStyle,
  TextBoldStyle,
} from 'src/styles/typography'

export const Box = styled(MuiBox)`
  background-color: ${props => props.theme.color.lightblue};
  position: relative;

  &:hover {
    background-color: ${props => darken(0.02, props.theme.color.lightblue)};
  }

  ${breakpoint.lg`
    height: 100%;
  `}
`

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -39px;
  height: 78px;
  width: 78px;
  padding: 16px;
  background-color: ${props => props.theme.color.green};
  border-radius: 50%;
`

export const Link = styled.a`
  height: 100%;
  width: 100%;
  text-decoration: none;
`

export const Title = styled.p`
  ${LinkWithArrowStyle}
  ${TextBoldStyle}
  color: ${props => props.theme.color.black};
  font-size: 1.25rem;
  margin: 0;

  &:hover {
    color: ${props => props.theme.color.black};
  }
`

export const Type = styled.p`
  ${TextDefaultStyle}
  color: ${props => props.theme.color.mediumgrey};
  font-style: italic;
  margin: 0;
`
