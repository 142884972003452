import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import WrapperPropTypes from 'src/utils/propTypes'
import Seo from 'src/components/Seo'
import CareerTemplate from 'src/templates/career'

const CareerWrapper = ({ data }) => {
  const page = data.wpPage
  const { seo } = page

  let ogImage = null
  if (seo.opengraphImage) {
    ogImage = {
      alt: seo.opengraphImage.altText,
      url: seo.opengraphImage.mediaItemUrl,
      width: seo.opengraphImage.mediaDetails.width,
      height: seo.opengraphImage.mediaDetails.height,
      type: seo.opengraphImage.mimeType,
    }
  }

  let twitterImage = null
  if (seo.twitterImage) {
    twitterImage = {
      alt: seo.opengraphImage.altText,
      url: seo.opengraphImage.mediaItemUrl,
      width: seo.opengraphImage.mediaDetails.width,
      height: seo.opengraphImage.mediaDetails.height,
      type: seo.opengraphImage.mimeType,
    }
  }

  return (
    <Layout page={data.wpPage} pageLocale={data.wpPage.language.locale}>
      <Seo
        title={page.title}
        locale={page.language.locale}
        description={seo.metaDesc}
        canonicalUri={data.wpPage.uri}
        og={{
          title: seo.opengraphTitle,
          description: seo.opengraphDescription,
          url: seo.opengraphUrl,
          image: ogImage,
          type: seo.opengraphType,
        }}
        twitter={{
          title: seo.twitterTitle,
          description: seo.twitterDescription,
          image: twitterImage,
        }}
      />
      <CareerTemplate acf={data.wpPage.acfCareer} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareerById($id: String!) {
    ...metadata
    wpPage(id: { eq: $id }) {
      title
      acfCareer {
        ...careerIntro
        ...careerJobs
      }
      seo {
        metaDesc
      }
      ...pageTranslationData
    }
  }
`

CareerWrapper.propTypes = WrapperPropTypes

export default CareerWrapper
